export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torna a entrega de comida online, supermercado, encomendas e compras em farmácias rápida e fácil. Receba mantimentos entregues e peça seus alimentos favoritos dos melhores Parceiros."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo em um só lugar"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça pedidos online, comida, serviços, espetinho, mercado, pedreiro, utensílios para o lar."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra parceiros locais que atendem na sua localidade"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juntos somos mais fortes"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se ao 7Bee e faça mais negócios é fácil. Descubra o caminho mais simples para o sucesso. Embarque agora e experimente o empreendedorismo sem complicações"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um Parceiro"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre-se como vendedor e abra uma loja para começar seu negócio hoje."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várias opções de pagamento"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão fácil de pedidos"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferecendo várias opções de pagamento"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizando do início ao fim."])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um parceiro e ganhe mais"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se ao 7Bee e desbloqueie seu potencial de ganhos. Aproveite a liberdade online e pagamento competitivo enquanto faz mais negócios."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um Parceiro"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne seu negócio online com o 7Bee"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifique seu negócio online com facilidade usando nosso aplicativo móvel. Gerencie seu negócio em qualquer lugar e mantenha-se conectado com seus clientes a qualquer hora, em qualquer lugar"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negócio"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fale Conosco"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos e Condições"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça Negócios Conosco"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisa de Parceiros?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer Vender no"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar Conta"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre uma loja"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direitos Autorais"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos os direitos reservados"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usamos cookies para melhorar sua experiência em nosso site. Ao navegar neste site, você concorda com o uso de cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Vendidos"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Recentes"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar produtos"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados da Busca"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados da Busca para"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado para"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Tudo"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Mais"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menos"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiros Próximos"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Você"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiros Populares"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre os melhores Parceiros na sua região"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temos todos os seus Parceiros favoritos aqui"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novidades"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Chegada"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoções Relâmpago"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoção Relâmpago"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livros"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livro"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolhas de Hoje"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum Parceiro"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher Arquivo"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços Populares"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Bem Avaliados"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em Estoque"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fora de Estoque"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Vendido"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao carrinho"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado para você"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos do mesmo Parceiro"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione opções para adicioná-las ao produto"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu Carrinho"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço Total do Produto"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar Compra"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio. Comece a comprar agora."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem extras disponíveis"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo do Pedido"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este subtotal não inclui o custo de deslocamento ou retirada"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de Itens"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço com Desconto"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconto"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor Total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem uma conta?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar um código de desconto"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter Desconto"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras com Criptografia SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para um checkout mais suave"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar Compra"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha deve ter pelo menos 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 número"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a Senha?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou Senha incorretos"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma conta?"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já tem uma conta?"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo de volta ao 7Bee!"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao Criar Conta você concorda com nossa Política de Privacidade e Termos & Condições"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Completo"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Telefone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEP"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Senha"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui você pede seus mantimentos favoritos de diferentes categorias"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido para Retirada"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, indique se você vai retirar o pedido no Parceiro"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, selecione o endereço de destino"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de Pagamento"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer Pedido"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de Destino"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Endereço"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido estará pronto em"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir Viagem"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Veículo"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça uma coleta de encomenda hoje"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem vai pagar?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remetente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie encomendas ou pacotes para amigos e familiares."])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações dos Destinatários e Parâmetros do Pacote"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários do Pacote"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do Pacote"])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastrear seu pacote"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir Agora"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos Recentes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Pacotes"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu serviço de encomenda local sob demanda"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar Tudo"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de Pacotes"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um Tipo de Pacote"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiros de Encomenda"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um Parceiro de encomenda"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do Pedido"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione Coleta e Destino aqui"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um local de Coleta"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um local de Destino"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar paradas"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um tipo de pacote"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um Parceiro"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do Destinatário"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de Contato"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao confirmar, aceito que este pedido não contém itens ilegais/restritos. Se itens ilegais/restritos forem encontrados pelo"])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiro, ele pode relatar às autoridades policiais. Termos e Condições aplicam-se."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do Pacote"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencha todos os parâmetros de pacote necessários aqui"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimento"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largura"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes do Pedido"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distância"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de Deslocamento"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de Tipo de Pacote"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do destinatário é obrigatório"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone do destinatário é obrigatório"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como você gostaria de receber seu pedido?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu Pedido"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosseguir para a finalização"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitamos as seguintes opções de pagamento"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Deslocamento"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gorjeta do Motorista"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer Pedido"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus Pedidos"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações da conta"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda não fez um pedido conosco. Quando fizer, eles aparecerão aqui."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produto"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações Pessoais"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olá"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de Senha"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus Favoritos"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha Antiga"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Senha"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Senha"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar Senha"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar Perfil"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem endereço"])}
  }
}